<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogGradingScaleCriterial"
        max-width="10000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline"
                >Manage Calculate Method's Marking Criteria</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogGradingScaleCriterial = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="2" class="d-flex">
                      <label style="font-size: 16px" class="p-0 mt-4"
                        >Name:
                      </label>
                      <v-text-field
                        v-model="name_input"
                        type="text"
                        style="max-width: 250px"
                        class="ml-4"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="d-flex">
                      <label style="font-size: 16px" class="p-0 mt-4"
                        >Min Score:
                      </label>
                      <v-text-field
                        v-model="min_score_input"
                        type="number"
                        style="max-width: 150px"
                        class="ml-4"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="d-flex">
                      <label style="font-size: 16px" class="p-0 mt-4"
                        >Max Score:
                      </label>
                      <v-text-field
                        v-model="max_score_input"
                        type="number"
                        style="max-width: 150px"
                        class="ml-4"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="d-flex">
                      <label style="font-size: 16px" class="p-0 mt-4"
                        >Step:
                      </label>
                      <v-text-field
                        v-model="step_input"
                        type="number"
                        style="max-width: 150px"
                        class="ml-4"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        color="blue darken-1"
                        outlined
                        rounded
                        class="mt-2"
                        @click="btnAddCriterial"
                      >
                        <v-icon color="">mdi-plus</v-icon>
                        Add New Criterial
                      </v-btn>
                    </v-col>
                    <v-col cols="1"></v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <div class="table-responsive">
                    <table
                      class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Min Score</th>
                          <th scope="col">Max Score</th>
                          <th scope="col">Step</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-for="(marking_criterial, i) in marking_criterials"
                        >
                          <tr :key="i">
                            <td scope="row">{{ i + 1 }}</td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ marking_criterial.name }}
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ marking_criterial.min_score }}
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ marking_criterial.max_score }}
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ marking_criterial.step }}
                              </p>
                            </td>
                            <td>
                              <div style="display: flex">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <button
                                      class="btn btn-icon btn-light-danger btn-sm ml-4"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="btnDelete(marking_criterial.id)"
                                    >
                                      <span
                                        class="svg-icon svg-icon-md svg-icon-primary"
                                      >
                                        <v-icon color="">mdi-delete</v-icon>
                                      </span>
                                    </button>
                                  </template>
                                  <span>Delete</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogGradingScaleCriterial = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "MarkingCriterial",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    grading_scale_id: {
      type: String,
      default: null,
    },
    grading_scale_skill_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      is_call_api: false,
      marking_criterials: [],
      name_input: null,
      min_score_input: null,
      max_score_input: null,
      step_input: null,
    };
  },
  computed: {
    dialogGradingScaleCriterial: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    show_dialog(val) {
      if (val) {
        this.name_input = null;
        this.min_score_input = null;
        this.max_score_input = null;
        this.step_input = null;
        this.getAllMarkingCriterial();
      }
    },
  },
  methods: {
    async getAllMarkingCriterial() {
      if (this.grading_scale_id && this.grading_scale_skill_id) {
        let vm = this;
        try {
          vm.is_call_api = true;
          let res = await ApiService.get(
            "prep-app/grading-scale/" +
              this.grading_scale_id +
              "/skills/" +
              this.grading_scale_skill_id +
              "/marking_criterial"
          );
          if (res.status == 200) {
            vm.marking_criterials = res.data;
            vm.is_call_api = false;
          }
        } catch (error) {
          vm.is_call_api = false;
        }
      }
    },
    btnAddCriterial() {
      let vm = this;
      let data = {
        name: this.name_input,
        min_score: this.min_score_input,
        max_score: this.max_score_input,
        step: this.step_input,
      };
      let validate = this.validateForm(data);
      if (!validate) {
        return;
      }
      ApiService.post(
        "prep-app/grading-scale/" +
          this.grading_scale_id +
          "/skills/" +
          this.grading_scale_skill_id +
          "/marking_criterial",
        data
      )
        .then(function (res) {
          if (res.status == 200) {
            vm.getAllMarkingCriterial();
          }
        })
        .catch(function (error) {
          if (error.response.status == 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    validateForm(data) {
      let flat = true;
      if (data.name == null || data.name == "") {
        this.$toasted.error("Hãy nhập name!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (data.min_score == null || data.min_score == "") {
        this.$toasted.error("Hãy nhập min score!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (data.max_score == null || data.max_score == "") {
        this.$toasted.error("Hãy nhập To max score!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (data.step == null || data.step == "") {
        this.$toasted.error("Hãy nhập step!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (data.max_score < data.min_score) {
        this.$toasted.error("max score phải lớn hơn min score!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnDelete(marking_criterial_id) {
      let vm = this;
      let data = {
        marking_criterial_id: marking_criterial_id,
      };
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.post(
            "prep-app/grading-scale/" +
              vm.grading_scale_id +
              "/skills/" +
              vm.grading_scale_skill_id +
              "/marking_criterial/delete",
            data
          ).then(function (res) {
            if (res.status == 200) {
              vm.getAllMarkingCriterial();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
